var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"location id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"location_id"}},[_vm._v("Locational ID")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.location_id),expression:"form.location_id"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"location_id","placeholder":"Locational ID"},domProps:{"value":(_vm.form.location_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "location_id", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"latitude","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"latitude"}},[_vm._v("Latitude")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.latitude),expression:"form.latitude"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"latitude","placeholder":"Latitude"},domProps:{"value":(_vm.form.latitude)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "latitude", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"longitude","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"longitude"}},[_vm._v("Longitude")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.longitude),expression:"form.longitude"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"longitude","placeholder":"Longitude"},domProps:{"value":(_vm.form.longitude)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "longitude", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"Altitude","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"Altitude"}},[_vm._v("Altitude")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Altitude),expression:"form.Altitude"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"Altitude","placeholder":"Altitude"},domProps:{"value":(_vm.form.Altitude)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Altitude", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"Ward","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"Ward"}},[_vm._v("Ward")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Ward),expression:"form.Ward"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"Ward","placeholder":"Ward"},domProps:{"value":(_vm.form.Ward)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Ward", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"Localname","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"Localname"}},[_vm._v("Localname")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Localname),expression:"form.Localname"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"Localname","placeholder":"Localname"},domProps:{"value":(_vm.form.Localname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Localname", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"Municipality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"Municipality"}},[_vm._v("Municipality")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Municipality),expression:"form.Municipality"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"Municipality","placeholder":"Municipality"},domProps:{"value":(_vm.form.Municipality)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Municipality", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"owner_name","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"owner_name"}},[_vm._v("Owner's Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.owner_name),expression:"form.owner_name"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"owner_name","placeholder":"Owner's Name"},domProps:{"value":(_vm.form.owner_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "owner_name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"started date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"started_date"}},[_vm._v("Started Date")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Started_date),expression:"form.Started_date"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"date","id":"started_date","placeholder":"Started Date"},domProps:{"value":(_vm.form.Started_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "Started_date", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"End date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label",attrs:{"for":"end_date"}},[_vm._v("End Date")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.End_date),expression:"form.End_date"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"date","id":"end_date","placeholder":"End Date"},domProps:{"value":(_vm.form.End_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "End_date", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"operational","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label"},[_vm._v("Operational")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Operational),expression:"form.Operational"}],staticClass:"form-control",class:{
                'is-invalid': errors[0],
                'is-valid': !errors[0] && !pristine,
              },attrs:{"id":"operational"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "Operational", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"true"}},[_vm._v("True")]),_c('option',{attrs:{"value":"false"}},[_vm._v("False")])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"variable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var pristine = ref.pristine;
return [_c('label',{staticClass:"form-label"},[_vm._v("Variable")]),_c('v-select',{class:{
                  'is-invalid': errors[0],
                  'is-valid': !errors[0] && !pristine,
                },attrs:{"options":_vm.options.variables,"label":"variable","multiple":""},model:{value:(_vm.form.variable),callback:function ($$v) {_vm.$set(_vm.form, "variable", $$v)},expression:"form.variable"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1)]),(!_vm.saving)?_c('button',{staticClass:"btn btn-primary btn-sm px-2 me-3",attrs:{"type":"submit"}},[_vm._v(" Save ")]):_c('button',{staticClass:"btn btn-primary btn-sm px-2 me-3",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" Loading... ")]),_c('button',{staticClass:"btn btn-sm btn-danger px-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Cancel ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }